<template>
  <div>
    <search-header :currentActive="7"></search-header>
    <div class="bg-light pb-4">
      <div class="container py-5">
        <div class="row text-center">
          <div class="col-12 h4">
            抚州土地信息发布
          </div>
          <div class="col-12 text-secondary mt-2">
            数据来源：抚州市农村综合产权服务中心
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row  bg-white rounded align-content-center">
          <div class="col   text-center ">
            <div class=" py-4" :class="{'border-bottom-2':current==1}" @click="current=1">
              <svg class="bi  mr-2 bi-app-indicator" width="1em" height="1em" viewBox="0 0 16 16"
                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              </svg>
              农用地
            </div>
          </div>

          <div class="col   text-center ">
            <div class=" py-4   " :class="{'border-bottom-2':current==2}" @click="current=2">
              <svg class="bi  mr-2 bi-app-indicator" width="1em" height="1em" viewBox="0 0 16 16"
                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              </svg>
              林地
            </div>
          </div>

          <div class="col   text-center ">
            <div class=" py-4   " :class="{'border-bottom-2':current==3}"  @click="current=3">
              <svg class="bi  mr-2 bi-app-indicator" width="1em" height="1em" viewBox="0 0 16 16"
                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"/>
                <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              </svg>
              农房
            </div>
          </div>

        </div>
      </div>

      <div class="container bg-white mt-4 rounded">
        <div class="row p-3  border-bottom justify-content-between align-content-center">
          <div class="">土地流转数据统计<span class="text-secondary">(数据源于官方发布)</span></div>
        </div>

        <div class="row ">
          <div class="col-12">
            <draw-line v-show="current==1" :option="nydOption"></draw-line>
            <draw-line v-show="current==2" :option="ldOption"></draw-line>
            <draw-line v-show="current==3" :option="nfOption"></draw-line>
          </div>
        </div>
      </div>


    </div>

    <map-footer></map-footer>
  </div>
</template>

<script>
import china from './components/china'
import drawLine from './components/drawLine'
import diagram from './components/line'

export default {
  data() {
    return {
      current: 1,
      nydOption: {
        color: '#4671C6',
        tooltip: {},
        xAxis: {
          data: ["总量(万亩)","招租量(万亩)","成交量(万亩)"],
        },
        yAxis: {},
        series: [{
          name: '平台流转数据',
          type: 'bar',
          data: [434.44, 0.26, 0.61]
        }]
      },
      ldOption: {
        color: '#4671C6',
        tooltip: {},
        xAxis: {
          data: ["总量(万亩)","招租量(万亩)","成交量(万亩)"],
        },
        yAxis: {},
        series: [{
          name: '平台流转数据',
          type: 'bar',
          data: [2732.77, 0, 0]
        }]
      },
      nfOption: {
        color: '#4671C6',
        tooltip: {},
        xAxis: {
          data: ["总量(万宗)","招租量(万宗)","成交量(万宗)"],
        },
        yAxis: {},
        series: [{
          name: '平台流转数据',
          type: 'bar',
          data: [89.12, 0, 0]
        }]
      }
    }
  },
  components: {
    china, drawLine,diagram
  }
}

</script>

<style scoped>
.border-bottom-2 {
  border-bottom: 2px solid #00cc00 !important;
}

.border-bottom-2 .h5 {
  margin-bottom: 0;
}

</style>
