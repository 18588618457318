<template>
  <div>
      <div :id="echarts" class="chart"></div>
  </div>
</template>

<script>
import echarts from 'china-map/node_modules/echarts'
export default {
  mounted() {
    this.$nextTick(()=>{
      this.drawChart()
    })
  },
  computed: {
    echarts () {
      return 'echarts' + Math.random()*100000
    }
  },
  props: {
    option: {
				type: Object,
				default: {}
    }
  },
  methods:{
    drawChart(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById(this.echarts))
      // 绘制图表
      myChart.clear()
      myChart.setOption(this.option)
      // 在渲染点击事件之前先清除点击事件
      myChart.off('click')
    }
  }
}
</script>

<style scoped>
.chart {
  width: 1140px;
  height: 800px;
}
</style>